
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApproveCertificate from "@/components/reusable/policy-information/documents-uploads/ApproveCertificate.vue";

export default defineComponent({
  name: "broker-approve-policy-certificate",
  components: { ApproveCertificate },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Approve Certificate", ["Broker", "Policies"]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title =
      "Broker Approve Policy Certificate | " + process.env.VUE_APP_TITLE;
  }
});
